




















import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';

@Component({
  components: {
  },
})
export default class OurPurpose extends Mixins(View) {}
